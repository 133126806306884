<template>
  <div>
    <div
      v-if="haveInheritedData"
      class="d-flex justify-content-end"
    >
      <div
        v-tippy="{ arrow: true }"
        class="small text-secondary text-right performance-from-group"
        :content="$t('items.inheritedFromGroup')"
      >
        <i class="fas fa-object-group pr-1" />
        <span v-if="!inheritedPerformance">-</span>
        <span v-else>
          {{ inheritedPerformance.value || '' }} {{ inheritedPerformance.unit || '' }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <BFormInput
        v-if="!unitOnly"
        v-model="speedValue"
        :disabled="pending"
        type="number"
        size="sm"
        style="width: 94px"
        class="mr-1 time-select"
        :formatter="v => Math.max(0, v)"
        @change="publish"
      />
      <select
        v-model="speedUnit"
        :disabled="pending"
        class="form-control form-control-sm time-select"
        style="width: 68px"
        :class="speedUnit ? '' : 'is-invalid'"
        @change="publish"
      >
        <option
          v-for="u in units"
          :key="u.code"
          :value="u.code"
        >
          {{ u.code }}
        </option>
      </select>
      <span class="slash-spacing">/</span>
      <select
        v-model="speedTime"
        :disabled="pending"
        class="form-control form-control-sm time-select"
        style="width: 68px"
        @change="publish"
      >
        <option
          v-for="t in time"
          :key="t.value"
          :value="t.value"
        >
          {{ t.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    pending: Boolean,
    value: Object,
    unitOnly: Boolean,
    haveInheritedData: Boolean,
    inheritedPerformance: Object,
  },
  data: () => ({
    speedValue: '',
    speedUnit: '',
    speedTime: 'h',
  }),
  computed: {
    ...mapState({
      units: state => state.units.list,
    }),
    time() {
      return [
        { value: 'h', text: 'h' },
        { value: 'min', text: 'min' },
        { value: 's', text: 's' },
      ];
    },
    unitsOptions() {
      if (!this.units) return [];
      return this.units
        .map(u => ({
          value: u.code,
          text: u.code,
        }))
        .concat({
          value: null,
          text: '?',
        });
    },
  },
  methods: {
    publish() {
      this.$emit('update:value', {
        value: this.speedValue,
        unit: `${this.speedUnit}/${this.speedTime}`,
      });
    },
  },
  created() {
    if (this.value) {
      this.speedValue = this.value.value;
      const perf = (this.value.unit || '').split('/');
      if (perf.length === 2) {
        this.speedUnit = (perf[0] || '');
        this.speedTime = perf[1];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .slash-spacing {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500;
  }

  .performance-from-group {
    font-size: 12px;
  }
</style>
